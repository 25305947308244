import React, {Fragment} from 'react';
import H from "@here/maps-api-for-javascript";
import _ from 'lodash';
import '../../../SiteManagement/homepage.css'
import determineBMPStatus from '../utils/determineBMPStatus';
import getIcon from '../utils/getIcon';
import getBMPMarker from '../utils/getBMPMarker';
import {handleFileUpload} from '../../../../utils'
import {WorkGroupsService} from '../../../../services'

//Switch the name of the function and the class to turn off the map if it's giving you problems in development.
function InspectionMapCores(props){
  return null;
}

class InspectionMapCore extends React.Component {

  constructor(props) {
    super(props);
    // the reference to the container
    this.ref = React.createRef()
    // reference to the map
    this.map = null;
    this.ui = null;
    this.sharedSiteMarker = null;
    this.markers = null;
    this.platform = null;

     let defaultCenter = {
        lat: 43.038902, lng: -87.906471,
      }

    let defaultZoom = 13;

    if (this.props.defaultCenter){
      defaultCenter = this.props.defaultCenter;
    }

    if (this.props.defaultZoom){
      defaultZoom = this.props.defaultZoom;
    }

    this.state = {
        location: JSON.parse(JSON.stringify(defaultCenter)),
        defaultCenter, defaultZoom,
        position: defaultCenter,
        zoom: defaultZoom,

        mapInstance: null,

        changeDefaultZoomCenterPrompt: false

       
    }
  }

  async componentDidUpdate(prevProps, prevState){

    if (this.props.displayMode){

      if (prevProps.capture !== this.props.capture /*&& this.map*/){
        if (this.map){ this.captureMapImg(); }
       // else {alert("No map yet")}
      }

      if (this.props.bmpList && this.props.bmpList.length != 0){
        this.renderMarkerList(this.props.bmpList);
      }

    } else if (this.props.displayMode && !prevProps.displayMode) {
      this.map.addEventListener('tap', this.onClickMap);
    } else if (!this.props.displayMode && prevProps.displayMode){
      this.map.removeEventListener('tap', this.onClickMap);
    } 
  }

   onMoveMap = ({newValue, oldValue}) => {

    if (this.props.displayMode){
        newValue = newValue.lookAt;
        oldValue = oldValue.lookAt;

        if (!_.isEqual(newValue, oldValue) && oldValue.zoom != 0){
          this.setState({changeDefaultZoomCenterPrompt: true, position: newValue.position, zoom: newValue.zoom})
        }
    }
  }

  async componentDidMount() {
    //console.log("Ghostly Overhang Debug", "calling componentDidMount", "==========================================================================");
    let {defaultZoom, defaultCenter} = this.state;
    if (!this.ref){
      this.ref = React.createRef();
    }

    if (!this.map) {
      // instantiate a platform, default layers and a map as usual
      const platform = new H.service.Platform({
        apikey: process.env.REACT_APP_HERE_API_KEY
      });

      //console.log("H.service:", H.service);

      const layers = platform.createDefaultLayers();
      console.log("InspectionMapCore layers:", layers)
      const map = new H.Map(
        this.ref.current,
        layers.raster.satellite.map,
        //layers.vector.normal.map,
        {
          pixelRatio: window.devicePixelRatio,
          center: defaultCenter,
          zoom: parseInt(defaultZoom)
        },
      );

      const ui = H.ui.UI.createDefault(map, layers);
      ui.setUnitSystem(H.ui.UnitSystem.IMPERIAL);
      //let zoom = ui.getControl("zoom");
      //console.log("Zoom:", zoom);
      //let zoom = new H.ui.ZoomControl({
      //  fractionalZoom: false,
      //  zoomSpeed: 1
      //})

     // ui.removeControl("zoom");
      //ui.removeControl("");
      //ui.addControl("zoom", zoom);
      const markers = new H.map.Group();

      var mapEvents = new H.mapevents.MapEvents(map);
      // Add event listeners:
      //map.addEventListener('mapviewchange', this.onZoomMap);

      if (this.props.displayMode == false){
        map.addEventListener('tap', this.onClickMap);
      }

      map.addEventListener('mapviewchange', this.onMoveMap);


      // add the interactive behaviour to the map, disabling double-tap to zoom as well
      var behavior = new H.mapevents.Behavior(mapEvents);
      behavior.disable(H.mapevents.Behavior.DBLTAPZOOM);

      this.map = map;
      this.ui = ui;
      this.markers = markers;
      this.platform = platform;
      this.behavior = behavior;

      this.map.addObject(this.markers);
    }

    window.addEventListener('resize', this.resizeMap);

    if (!this.props.displayMode) await this.generateIcons();
    else {
      if (this.props.bmpList && this.props.bmpList.length != 0){
        this.renderMarkerList(this.props.bmpList);
      }
    }

    this.resizeMap();

    console.log("mapCurrentlyLoaded callback:", this.props.mapLoadedCallback, typeof this.props.mapLoadedCallback)

    if (this.props.mapLoadedCallback && typeof this.props.mapLoadedCallback == "function"){
      setTimeout(this.props.mapLoadedCallback, 0)
    }
    //this.addMarker(this.state.defaultCenter);
  }

  generateIcons = async () => {
    let icons = getIcon();

    let newState = {};

    for (var i = 0; i < icons.length; i++){
      console.log("Generating icon:", icons[i]);

      if (this.state[icons[i].key] === undefined){
        newState[icons[i].key] = new H.map.Icon(icons[i].val, {size: {w: 25, h: 25}});
      }
    }

    console.log("generateIcons newState:", newState);
    await this.promisedSetState(newState);

    return;
  }

  renderMarkerList = (bmpList) => {
    if (!bmpList && !this.props.bmpList) return;
    if (!bmpList){
      bmpList = this.props.bmpList;
    }

    for (var i = 0; i < bmpList.length; i++){
      this.addMarker(bmpList[i]);    
    }
  }

  captureMapImg = async () => {
    console.log("Calling captureMapImg with props displayMode", this.props.displayMode, "getMapImageCallback", this.props.getMapImageCallback);
    if (this.props.displayMode && this.props.getMapImageCallback){
      console.log("captureMapImg map to capture:", this.map)
       this.map.capture(async (canvas) => {
        let canvasURL = canvas.toDataURL()
        console.log("captureMapImg canvasURL:", canvasURL);
        let imageUrl = handleFileUpload(canvasURL, "reports", this.props.getMapImageCallback)
      }, [this.markers, this.ui])
    }
  }



  promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

  resizeMap = () => {
    this.map.getViewPort().resize();
  }

  addMarker = (bmp) => {
    console.log("addMarker param:", bmp);
    let icon = null;
    if (this.props.displayMode){
      icon = new H.map.Icon(getBMPMarker(bmp.letterLabel, determineBMPStatus(bmp, this.props.reportState), "map"));
    } else {
       icon = this.state['my-material-surp']
    }
    let marker = new H.map.Marker(bmp.location, { icon });
    this.markers.addObject(marker);
    return marker;
  }


  componentWillUnmount = async () => {
    this.ref = null;
    
    //console.log("Ghostly Overhang Debug", "Calling componentWillUnmount");

    if (this.map) {
      this.map.removeEventListener('tap', this.onClickMap);
      //this.map.removeEventListener('mapviewchange', this.onZoomMap);
      if (this.markers) {
	      //console.log("Ghostly Overhang Debug", "Calling markers.removeAll");
	      this.markers.removeAll();
	    }

      this.map.removeObjects(this.map.getObjects());
    }

    window.removeEventListener('resize', this.resizeMap);
  }



  onClickMap = (evt) => {
   
    console.log("onClickMap")

    //console.log("onClickMap", "Map locked?", locked);
    if (!(evt.target instanceof H.map.Marker)){

      if (evt.currentPointer){

          this.markers.removeAll();

          let location = this.map.screenToGeo(evt.currentPointer.viewportX, evt.currentPointer.viewportY);
          console.log("Calculated location:", location);

          this.addMarker({location})

          this.setState({location});
          this.props.callback(location);
      }
    }

  }

  //onZoomMap = ({newValue}) => {
  //  let {lookAt} = newValue;
  //  if (lookAt && lookAt.position && lookAt.zoom){
  //    this.setState({position: lookAt.position, zoom: lookAt.zoom});
  //  }
  //}


  render() {
  	return (<>
      <div 
        style={{ width: '100%', height:'inherit', maxHeight: 'inherit', minHeight: 'inherit'}} 
        className="sandbox-wrapper" 
        ref={this.ref}>
          
      </div>

      {this.state.changeDefaultZoomCenterPrompt ? 
        <div className="alert alert-warning mt-6 mx-auto" role="alert">
          <p>Would you like the current map position to be how the map appears in inspection reports? (Clicking yes will cause the page to refresh)</p>
           <button 
            type="button" 
            className="btn btn-warning mt-2" 
            onClick={async () => {
              let params = {
                idsite: this.props.reportState.idsite,
                idworkgroup: this.props.reportState.workUnit.idworkgroup, 
                visibility: 1, 
                zoomLevel: this.state.zoom, 
                position: this.state.position
              }

              console.log("InspectionMapCore updating site visibility with params", params)

              await WorkGroupsService.setSiteVisibility(params);

              this.setState({changeDefaultZoomCenterPrompt: false}, () => {window.location.reload()});

            }}>
            Yes
          </button>
        </div> 
      : null}
    </>);
  }
}

export default InspectionMapCore;