import { url } from '../constants'
import JwtDecode from 'jwt-decode'

export const WorkGroupsService = {
  getUserWorkGroups,
  getWorkGroupSites,
  sendInvite,
  setSiteVisibility,
  getSkills,
  addSkill,
  editEmployee,
  getEmployees,
  removeEmployee,
  createNewWorkGroup,
  bulkSetSiteVisibility,
  getMyOtherSites,
  updateSitePhases
}

async function getUserWorkGroups() {
  const userJWT = localStorage.getItem('token')
  const userDecoded = JwtDecode(userJWT)
  const email = userDecoded ? userDecoded['email'] : null
  const resp = await fetch(`${url.backend}/workgroups/getUserWorkGroups/${email}`).then(handleResponse)
  return resp.workGroups
}

async function getWorkGroupSites(idworkgroup) {
  const resp = await fetch(`${url.backend}/workgroups/getWorkGroupSites/${idworkgroup}`).then(handleResponse)
  return resp.sites
}

async function createNewWorkGroup(data){
  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch (`${url.backend}/workgroups/createNewWorkGroup/`, requestOptions)
        .then (handleResponse);
}

async function sendInvite(data){
  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch (`${url.backend}/workgroups/sendInvite/`, requestOptions)
        .then (handleResponse);
}

async function setSiteVisibility(data){
  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch (`${url.backend}/workgroups/setSiteVisibility/`, requestOptions)
        .then (handleResponse); 
}

async function getSkills() {
  const resp = await fetch(`${url.backend}/workgroups/getSkills`).then(handleResponse)
  return resp.skillsList
}

async function addSkill(data){
  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch (`${url.backend}/workgroups/addSkill/`, requestOptions)
        .then (handleResponse);
}

async function editEmployee(data){
  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch (`${url.backend}/workgroups/editEmployee/`, requestOptions)
        .then (handleResponse);
}

async function getEmployees(idworkgroup) {
  const resp = await fetch(`${url.backend}/workgroups/getEmployees/${idworkgroup}`).then(handleResponse)
  return resp.employees
}

async function removeEmployee(data){
  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch (`${url.backend}/workgroups/removeEmployee/`, requestOptions)
        .then (handleResponse);
}

async function getMyOtherSites(idworkgroup){
  const userJWT = localStorage.getItem('token')
  const userDecoded = JwtDecode(userJWT)
  const username = userDecoded ? userDecoded['cognito:username'] : null
  const resp = await fetch(`${url.backend}/workgroups/getMyOtherSites/${username}/${idworkgroup}`).then(handleResponse)
  return resp.sites
}

async function bulkSetSiteVisibility(data){
  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch (`${url.backend}/workgroups/bulkSetSiteVisibility/`, requestOptions)
        .then (handleResponse);
}

async function updateSitePhases(data){
  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch (`${url.backend}/workgroups/updateSitePhases`, requestOptions)
        .then (handleResponse);
}


function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
