import React, {Fragment, useState, useEffect} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types'

function SelectedCAsDisplay({
	passedCas,
	useListView

}){

	let getSelectedCAs = () => {
		console.log("Calling getSelectedCAs");
		let selectedCAs = [];

		if (passedCas !== null) {
			for (var i = 0; i < passedCas.length; i++){
				if (passedCas[i].status) selectedCAs.push(_.cloneDeep(passedCas[i]));
			}
		}
		
		return selectedCAs;
	}

	let [cas, setCAs] = useState([]);

	useEffect(() => {
		setCAs(getSelectedCAs)
	}, [passedCas])

	if (cas === null) return <>Documentation Only</>

	else if (!useListView){
		if (cas && cas.length && cas.length > 0){
			return cas.map((ca, index, arr) => {
		        return (<>
					<label className="" key={ca.idaction}>{ca.name}</label>
					{index < arr.length-1 ? ", " : ""}
				</>)
		    })
	    }
	    
	    else return <span>Error: No corrective actions found</span>
    } else {
    	if (cas && cas.length && cas.length > 0){
	    	return(<div class="form-group ml-6 pb-4 border-bottom">
				<ul class="pb-2 pl-0">

					{cas.map((ca, index, arr) => {
						return <li class="">
							<label class="fs-17" key={ca.idaction}>{ca.name}</label>
						</li>
					})}

				</ul>

		    </div>);
		}

		else return <span class="fs-17">Error: No corrective actions found</span>
    }  

}


SelectedCAsDisplay.defaultProps = {
	useListView: false
}

export default SelectedCAsDisplay;