import React, {Fragment, useState, useEffect} from 'react';
import { url } from '../../../../constants'
import _ from 'lodash'
import determineBMPStatus from '../utils/determineBMPStatus';
import setActiveBMP from '../utils/setActiveBMP';
import getBMPMarker from '../utils/getBMPMarker';
import identifyIsDocumentationOnlyBMP from '../utils/identifyIsDocumentationOnlyBMP';
import SelectedCAsDisplay from './SelectedCAsDisplay'

export default function LoggedBMPDisplay({handleChange, passedBMP, reportState}){

	let [bmp, setBMP] = useState(_.cloneDeep(passedBMP));
	let [status, setStatus] = useState("");
	let [thumbnailImage, setThumbnailImage] = useState('../img/thumb/0.png')

	useEffect(() => {
		setStatus(determineBMPStatus(bmp, reportState));

	}, [bmp])

	useEffect(() => {

		let {correctionDocumentation, conditionDocumentation} = bmp;
		let img = "../img/thumb/0.png";

		if (!_.isEmpty(correctionDocumentation.photos)){
			img = url.backend + "/images/practiceactions/" + correctionDocumentation.photos[0]
		} else if (!_.isEmpty(conditionDocumentation.photos)){
			img = url.backend + "/images/practiceactions/" + conditionDocumentation.photos[0]
		}

		setThumbnailImage(img);

	}, [status]);

	let moveOn = () => {
		setActiveBMP(handleChange, reportState, true, bmp.bmpIndex);
		handleChange("currentBMPCorrected", (status != "Complete"))
		handleChange('phase', ('BMPEventSummary' + ((status == "Complete" || identifyIsDocumentationOnlyBMP(bmp)) ? "Complete" : "Incomplete") ));
	}

	let goToEdit = () => {
		setActiveBMP(handleChange, reportState, true, bmp.bmpIndex);
		handleChange("currentBMPCorrected", (status != "Complete"))
		handleChange('phase', 'BMPEventSummaryIncomplete')
	}

	return(<>
		
		<div className="col-11 container border px-0 mb-5">
			<div className="card">
				<div className="row no-gutters">
					<div className="col-sm-5">
						<img className="card-img" src={thumbnailImage} alt="Inlet Protection" />
					</div>
					<div className="col-sm-7">
						<div className="card-body">

							<div className="clearfix">
								{getBMPMarker(bmp.letterLabel, status, "list")}
								<p className="card-title lead strong float-left">{bmp.name}</p>
								{/*status != "Complete" ? <button className="btn btn-link float-right" onClick={goToEdit}>✏️</button> : null */}
							</div>

							{!identifyIsDocumentationOnlyBMP(bmp) ? 
								<div className="mb-3">
									<p className="mb-0"><strong>Required Corrective Actions:</strong></p>
									<SelectedCAsDisplay
						        		passedCas={bmp.cas}
						        	/> 
								</div>
							: null}

							<div className="flexbox">

								{status == "Incomplete" ? <>
									<p className="strong text-warning fs-16 mt-3"><i className="fa fa-exclamation-triangle mr-2 text-warning"></i>Incomplete</p>
									<a href="#" onClick={moveOn} className="btn btn-xs btn-round btn-warning">{reportState.status == "finished" ? "View Record" : "Correct BMP"}</a>
								</> : status == "Overdue" ? <>
									<p className="strong text-danger fs-16 mt-3"><i className="fa fa-clock-o mr-2 text-danger"></i>Overdue</p>
                                    <a href="#" onClick={moveOn} className="btn btn-xs btn-round btn-danger">{reportState.status == "finished" ? "View Record" : "Correct BMP"}</a>
								</> : status == "Documentation" ? <>
									<p className="strong text-primary fs-16 mt-3"><i className="fa fa-check-square mr-2 text-primary"></i>Documentation Only</p>
                                    <a href="#" onClick={moveOn} className="btn btn-xs btn-round btn-outline-primary">View Record</a>
								</> : <>
									<p className="strong text-success fs-16 mt-3"><i className="fa fa-check-square mr-2 text-success"></i>Complete</p>
                                    <a href="#" onClick={moveOn} className="btn btn-xs btn-round btn-outline-success">View Record</a>
								</>}

							</div>

						</div>
					</div>
				</div>
			</div>
		</div>

	</>)

}